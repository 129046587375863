<template>
  <div>
    <a-card :title="$t('库存报表')">
      <div slot="extra">{{$t("库存总值")}}: {{ totalAmount }}</div>

      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-input-search v-model="searchForm.search" :placeholder="$t('产品编号, 名称')" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" @click="search" style="width: 100%">{{ $t("查询") }}</a-button>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button icon="download" @click="exportData" style="width: 100%">{{ $t("导出") }}</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            @change="changeTable"
          />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { warehouseInventoryList, warehouseInventoryExport, warehouseInventoryTotalAmount } from "@/api/report";
import { exportExcel } from "@/utils/excel";

export default {
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,
      totalAmount: 0,

      // Table
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: this.$t("仓库"),
          dataIndex: "warehouse_name",
          customRender: (_value, item) => item.warehouse_item.name,
        },
        {
          title: this.$t("产品编号"),
          dataIndex: "material_number",
          customRender: (_value, item) => item.material_item.number,
        },
        {
          title: this.$t("产品名称"),
          dataIndex: "material_name",
          customRender: (_value, item) => item.material_item.name,
        },
        {
          title: this.$t("库存数量"),
          dataIndex: "quantity",
        },
        {
          title: this.$t("不良品数量"),
          dataIndex: "unqualified_quantity",
        },
        {
          title: this.$t("库存单位"),
          dataIndex: "unit",
          customRender: (_value, item) => item.material_item.unit,
        },
        {
          title: this.$t("产品单价"),
          dataIndex: "unit_price",
          customRender: (_value, item) => item.material_item.unit_price,
        },
      ],
      dataItems: [],
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      warehouseInventoryList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });

      warehouseInventoryTotalAmount(this.searchForm).then((data) => {
        this.totalAmount = data.total_amount;
      });
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    exportData() {
      warehouseInventoryExport(this.searchForm).then((resp) => {
        exportExcel(resp, this.$t("库存报表"));
      });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
